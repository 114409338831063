import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useRef } from "react"
import NavItems from "./navItems"
import SmallScreenMenu from "./smallScreenMenu"
import nonHomeHeaderStyles from "./nonHomeHeader.module.scss"
import commonHeaderStyles from "./commonHeader.module.scss"

const NonHomeHeader = ({ isSmallScreen }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle
        }
      }
    }
  `)

  const headerRef = useRef(null)

  const handleScroll = () => {
    const refCurrent = headerRef.current
    if (!refCurrent) return
    const classOpaque = nonHomeHeaderStyles.fixedHeaderOpaque
    window.scrollY > 45
      ? refCurrent.classList.add(classOpaque)
      : refCurrent.classList.remove(classOpaque)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <header ref={headerRef}>
      <nav
        className={`${nonHomeHeaderStyles.fixedHeader} ${commonHeaderStyles.commonNav}`}
      >
        <span className={nonHomeHeaderStyles.navAndTitleContainer}>
          <h1 className={nonHomeHeaderStyles.stickyTitle}>
            <Link to="/">{data.site.siteMetadata.defaultTitle}</Link>
          </h1>
          {isSmallScreen ? <SmallScreenMenu /> : <NavItems />}
        </span>
      </nav>
    </header>
  )
}

export default NonHomeHeader
