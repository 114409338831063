import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import footerStyles from "./footer.module.scss"

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            defaultTitle
          }
        }
      }
    `
  )
  return (
    <div className={footerStyles.footer}>
      <footer>
        <div className={footerStyles.footerContent}>
          <div className={footerStyles.mainContent}>
            <div>
              <Link to="/" className={footerStyles.siteTitleLink}>
                {data.site.siteMetadata.defaultTitle}
              </Link>
            </div>
            <div>
              <Link to="/contact-me/" className={footerStyles.contactLink}>
                Contact Me
              </Link>
            </div>
          </div>
          <div className={footerStyles.copyright}>
            © {data.site.siteMetadata.author} - 2020
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
