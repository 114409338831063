import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useRef } from "react"
import NavItems from "./navItems"
import SmallScreenMenu from "./smallScreenMenu"
import headerStyles from "./header.module.scss"
import commonHeaderStyles from "./commonHeader.module.scss"

const Header = ({ isSmallScreen }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle
        }
      }
    }
  `)

  const navRef = useRef(null)

  const handleScroll = () => {
    const refCurrent = navRef.current
    if (!refCurrent) return
    let stickyPosition = 325

    if (window.innerWidth < 1282) {
      stickyPosition = 300
    }
    if (window.innerWidth < 962) {
      stickyPosition = 200
    }
    if (window.innerWidth < 642) {
      stickyPosition = 120
    }

    if (window.innerWidth < 482) {
      stickyPosition = 100
    }

    if (window.scrollY >= stickyPosition) {
      navRef.current.classList.add(
        headerStyles.fixedHeader,
        headerStyles.fixedHeaderOpaque
      )
    } else {
      navRef.current.classList.remove(
        headerStyles.fixedHeader,
        headerStyles.fixedHeaderOpaque
      )
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  const navContainer = (
    <header>
      <nav ref={navRef} className={commonHeaderStyles.commonNav}>
        <span className={headerStyles.navAndTitleContainer}>
          <h1 className={headerStyles.title}>
            <Link to="/">{data.site.siteMetadata.defaultTitle}</Link>
          </h1>
          {isSmallScreen ? <SmallScreenMenu /> : <NavItems />}
        </span>
      </nav>
    </header>
  )

  return (
    <>
      <div className={headerStyles.headerBanner}></div>
      {navContainer}
    </>
  )
}

export default Header
