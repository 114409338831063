const SLASH = "/"

export const getSEOUrl = (pathName, siteUrl) => {
  if (pathName === SLASH) {
    return `${siteUrl}${pathName}`
  }

  return pathName.slice(-1) === SLASH
    ? `${siteUrl}${pathName}`
    : `${siteUrl}${pathName}${SLASH}`
}
