import React from "react"
import { Link } from "gatsby"
import navItemStyles from "./navItems.module.scss"

export const links = [
  { text: "Home", to: "/" },
  { text: "About Me", to: "/about-me/" },
  { text: "CBT Sessions", to: "/cbt-sessions/" },
  { text: "Contact Me", to: "/contact-me/" },
  { text: "FAQ", to: "/faq/" },
  { text: "Blog", to: "/blog/" }
]

const NavItems = () => {
  return (
    <ul>
      {links.map(({ text, to }) => (
        <li key={to}>
          <Link
            to={to}
            className={navItemStyles.navItem}
            activeClassName={navItemStyles.navItemActive}
          >
            {text}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default NavItems
