import React from "react"
import { links } from "./navItems"
import { navigate } from "gatsby"
import { Menu, Grommet } from "grommet"
import { Menu as MenuIcon } from "grommet-icons"
import { grommet } from "grommet/themes"
import { deepMerge } from "grommet/utils"
import { Close } from "grommet-icons"

const menuItemColour = "#31708e"
const closeIcon = () => <Close size="small" color={menuItemColour} />

const customMenuTheme = deepMerge(grommet, {
  global: {
    colors: {
      backgroundColor: "green",
      background: "green",
      color: "green"
    },
    focus: {
      border: {
        color: "#8fc1e3"
      }
    },
    hover: {
      color: menuItemColour
    }
  },
  menu: {
    size: "xsmall",
    icons: {
      color: "#f7f9fb",
      down: MenuIcon,
      up: closeIcon
    }
  }
})

const smallNavMenuItems = links.map(({ to, text }) => ({
  label: text,
  onClick: () => navigate(to),
  color: menuItemColour
}))

const SmallScreenMenu = () => {
  return (
    <Grommet
      theme={customMenuTheme}
      style={{
        marginBottom: "10px",
        marginRight: "1rem",
        backgroundColor: "#31708e"
      }}
    >
      <Menu items={smallNavMenuItems} />
    </Grommet>
  )
}

export default SmallScreenMenu
