import React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import { getSEOUrl } from "../utils/getSEOUrl"

const IMAGE_URL =
  "https://www.gavinlashleytherapy.com/android-chrome-192x192.png"

const Head = ({ title, description }) => {
  const {
    site: { siteMetadata }
  } = useStaticQuery(query)
  const { defaultTitle, defaultDescription, siteUrl } = siteMetadata

  const { pathname } = useLocation()
  const seo = {
    title: title ? `${title} | ${defaultTitle}` : defaultTitle,
    description: description ? description : defaultDescription,
    url: getSEOUrl(pathname, siteUrl),
    ogType: "website",
    imageUrl: IMAGE_URL,
    twitterCard: "summary",
    twitterImageUrl: IMAGE_URL
  }

  return (
    <Helmet title={seo.title}>
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      <html lang="en" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <meta
        name="google-site-verification"
        content="iHAJsrALDnsEzP1HZhIHTPD6nGUWXfRcXrSYxt7yjUY"
      />
      {seo.ogType && <meta property="og:type" content={seo.ogType} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.imageUrl && <meta property="og:image" content={seo.imageUrl} />}
      {seo.twitterCard && (
        <meta name="twitter:card" content={seo.twitterCard} />
      )}
      {seo.twitterImageUrl && (
        <meta name="twitter:image" content={seo.twitterImageUrl} />
      )}
    </Helmet>
  )
}

export default Head

const query = graphql`
  query Head {
    site {
      siteMetadata {
        defaultTitle
        defaultDescription
        siteUrl
      }
    }
  }
`
